import logo from "./logo_bg_white_round.png";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div>
          <div>
            <img src={logo} />
          </div>
          <h1>
            Welcome to <span className="App-text">Flowallway</span>
          </h1>
        </div>
      </header>
    </div>
  );
}

export default App;
